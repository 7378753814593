import React, { useState } from 'react';
import PageHeader from "../component/PageHeader";
import Footer from "../component/Footer";
import PricingModal from "../component/PricingModal";
import dealTableBgImage from "../assets/deal-table.jpg";
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';

const ProcessTimeline = () => {
  const steps = [
    { 
      title: "Application Submission", 
      icon: "mdi:file-document-outline",
      content: "Submit detailed project proposal, financial projections, ROI analysis, cast & crew profile, current funding status, and investment needs at least 6 weeks prior to the AFFF event date."
    },
    { 
      title: "Initial Screening & Evaluation", 
      icon: "mdi:magnify",
      content: "AFFF Selection Committee reviews submissions based on ROI potential, uniqueness, scalability, budget viability, production team strength, and marketability."
    },
    { 
      title: "Shortlisting of Projects", 
      icon: "mdi:format-list-checks",
      content: "10-15 projects with the highest potential are shortlisted. Selected filmmakers receive a Deal Table Pass for premium networking and briefings."
    },
    { 
      title: "Deal Table Prep & Strategy Briefing", 
      icon: "mdi:strategy",
      content: "Shortlisted applicants attend a preparation session to refine pitches, strategize negotiations, and understand the Deal Table format."
    },
    { 
      title: "Deal Table Appointment Scheduling", 
      icon: "mdi:calendar-clock",
      content: "Selected projects are assigned 30-45 minute slots at the Deal Table to pitch directly to Premium Elite Plus financiers, top sales agents, and production companies."
    },
    { 
      title: "The Deal Table Session", 
      icon: "mdi:table-furniture",
      content: "Intimate boardroom-style environment for pitching projects, securing financing or distribution deals, and forging partnerships with top industry players."
    },
    { 
      title: "Post-Event Follow-Up", 
      icon: "mdi:handshake",
      content: "AFFF facilitates follow-up meetings between filmmakers and financiers to finalize deals and negotiate terms, with additional support for legal agreements."
    },
    { 
      title: "Additional Opportunities", 
      icon: "mdi:star-plus-outline",
      content: "Projects not selected for the Deal Table still have access to Investor's Room, Pitch Sessions, and Networking Lounges for other financing and collaboration opportunities."
    }
  ];

  return (
    <div className="mt-12">
      <div className="relative">
        {steps.map((step, index) => (
          <div key={index} className="mb-12 flex items-start">
            <div className="flex items-center justify-center w-20 h-20 bg-custom-red rounded-full flex-shrink-0">
              <Icon icon={step.icon} className="text-white text-4xl" />
            </div>
            <div className="ml-6 flex-grow">
              <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
              <div className="p-4 border border-gray-300 rounded-lg bg-white shadow-md">
                <p>{step.content}</p>
              </div>
            </div>
          </div>
        ))}
        <div className="absolute top-0 bottom-0 left-10 w-0.5 bg-gray-200 -ml-0.5"></div>
      </div>
    </div>
  );
};

const DealTablePage = () => {
  const [isPricingModalOpen, setIsPricingModalOpen] = useState(false);

  return (
    <div className="relative min-h-screen flex flex-col bg-[#FAF0E6] font-lexend">
      <PageHeader 
        title="THE DEAL TABLE" 
        description="An ultra-exclusive boardroom for high-budget film projects with substantial ROI potential."
        backgroundImage={dealTableBgImage}
      />
      <main className="flex-grow p-4 sm:p-6 flex flex-col items-center justify-center">
        <div className="container mx-auto px-4 py-8 max-w-6xl mx-6">
          <div className="border-l-4 sm:border-l-8 border-[#A18749] pl-2 sm:pl-4 mb-8">
            <h2 className="font-header text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-2 tracking-wider text-custom-base">
              Process for Securing a Spot at The Deal Table
            </h2>
          </div>
          
          <ProcessTimeline />

          <section className="mt-12">
            <h2 className="text-2xl font-bold mb-4">Important Notes:</h2>
            <ul className="list-disc pl-6 space-y-2">
              <li><strong>Exclusivity:</strong> Only the most promising projects with a clear pathway to profitability will be admitted to the Deal Table.</li>
              <li><strong>Premium Pass:</strong> Projects with Deal Table Access will receive premium passes, which include other exclusive networking opportunities, masterclasses, and panels.</li>
              <li><strong>Networking Benefit:</strong> Even after the event, all Deal Table participants remain part of the AFFF Elite Network, providing ongoing access to potential investors and collaborators throughout the year.</li>
              <li><strong>More Information:</strong> Bernice on <strong>+234 814 655 8068</strong> or send us an email at: <strong>opportunities@africafilmfinanceforum.com</strong></li>
            </ul>
          </section>
          
          <section className="text-center mt-16 mb-16 p-4 border border-gray-300 rounded-lg bg-white shadow-md">
            <p className="mb-1 p-12 sm:text-xl leading-relaxed">
              The Deal Table is an ultra-exclusive boardroom designed for high-budget film projects with substantial
              ROI potential. Access is limited to Premium Elite Plus financiers and top Sales & Production companies. It
              offers a sophisticated environment for high-level networking, in-depth negotiations, and strategic
              discussions, making it the ideal setting for securing significant investments and forging elite business
              partnerships.
            </p>
            <div className="flex justify-center mb-8 pb-2">
              <Icon icon="mdi:handshake-outline" className="text-[7rem] text-custom-red mr-4" />
              <Icon icon="mdi:movie-open-outline" className="text-[7rem] text-custom-red" />
            </div>
            <Link 
                to="/register" 
                className="px-10 py-2 mt-4 mb-6 pb-6 uppercase font-bold cursor-pointer bg-custom-red rounded-tl-lg rounded-br-lg text-white font-roboto text-[18px] inline-block"
              >
              GET YOU BADGE
          </Link>
          </section>
          
          
        </div>
      </main>
      <Footer />
      <PricingModal isOpen={isPricingModalOpen} onClose={() => setIsPricingModalOpen(false)} />
    </div>
  );
};

export default DealTablePage;