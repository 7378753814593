// PaymentStep.jsx
import React, { useState } from 'react';

const PaymentStep = ({ amount, onBack, onSubmit, currency = { code: 'USD', symbol: '$' }, isSubmitting, error }) => {
  const [paymentMethod, setPaymentMethod] = useState('creditCard');
  const [cardDetails, setCardDetails] = useState({
    cardNumber: '',
    expiryDate: '',
    cvv: '',
    name: ''
  });

  const validateCardNumber = (number) => /^[\d\s-]{16,19}$/.test(number.replace(/\s/g, ''));
  const validateExpiry = (expiry) => /^(0[1-9]|1[0-2])\/([0-9]{2})$/.test(expiry);
  const validateCVV = (cvv) => /^[0-9]{3,4}$/.test(cvv);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    // Format card number with spaces
    if (name === 'cardNumber') {
      formattedValue = value.replace(/\s/g, '').replace(/(.{4})/g, '$1 ').trim();
    }
    // Format expiry date
    else if (name === 'expiryDate') {
      formattedValue = value
        .replace(/\s/g, '')
        .replace(/(\d{2})(\d)/, '$1/$2')
        .slice(0, 5);
    }
    // Limit CVV length
    else if (name === 'cvv') {
      formattedValue = value.slice(0, 4);
    }

    setCardDetails(prev => ({
      ...prev,
      [name]: formattedValue
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (paymentMethod === 'creditCard') {
      if (!validateCardNumber(cardDetails.cardNumber)) {
        alert('Please enter a valid card number');
        return;
      }
      if (!validateExpiry(cardDetails.expiryDate)) {
        alert('Please enter a valid expiry date (MM/YY)');
        return;
      }
      if (!validateCVV(cardDetails.cvv)) {
        alert('Please enter a valid CVV');
        return;
      }
      if (!cardDetails.name.trim()) {
        alert('Please enter the cardholder name');
        return;
      }
    }

    onSubmit({ paymentMethod, ...cardDetails });
  };

  const formatCurrency = (value) => {
    return `${currency.symbol}${value.toLocaleString(undefined, { 
      minimumFractionDigits: 2,
      maximumFractionDigits: 2 
    })}`;
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-bold mb-6">Payment Information</h2>
      
      <div className="mb-6 bg-gray-50 p-4 rounded-lg">
        <p className="font-medium text-gray-700">Total Amount:</p>
        <p className="text-2xl font-bold text-green-600">{formatCurrency(amount)}</p>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Payment Method
          </label>
          <select
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
            className="w-full border border-gray-300 rounded-md p-2 focus:ring-2 focus:ring-custom-red focus:border-transparent"
          >
            <option value="creditCard">Credit/Debit Card</option>
            <option value="paypal">PayPal</option>
            <option value="bankTransfer">Bank Transfer</option>
          </select>
        </div>

        {paymentMethod === 'creditCard' && (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Card Number
              </label>
              <input
                type="text"
                name="cardNumber"
                value={cardDetails.cardNumber}
                onChange={handleInputChange}
                placeholder="1234 5678 9012 3456"
                className="w-full border border-gray-300 rounded-md p-2 focus:ring-2 focus:ring-custom-red focus:border-transparent"
                required
                maxLength="19"
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Expiry Date
                </label>
                <input
                  type="text"
                  name="expiryDate"
                  value={cardDetails.expiryDate}
                  onChange={handleInputChange}
                  placeholder="MM/YY"
                  className="w-full border border-gray-300 rounded-md p-2 focus:ring-2 focus:ring-custom-red focus:border-transparent"
                  required
                  maxLength="5"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  CVV
                </label>
                <input
                  type="password"
                  name="cvv"
                  value={cardDetails.cvv}
                  onChange={handleInputChange}
                  placeholder="123"
                  className="w-full border border-gray-300 rounded-md p-2 focus:ring-2 focus:ring-custom-red focus:border-transparent"
                  required
                  maxLength="4"
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Cardholder Name
              </label>
              <input
                type="text"
                name="name"
                value={cardDetails.name}
                onChange={handleInputChange}
                placeholder="John Doe"
                className="w-full border border-gray-300 rounded-md p-2 focus:ring-2 focus:ring-custom-red focus:border-transparent"
                required
              />
            </div>
          </div>
        )}

        {paymentMethod === 'paypal' && (
          <div className="bg-blue-50 p-4 rounded-md">
            <p className="text-blue-700">
              You will be redirected to PayPal to complete your payment securely.
            </p>
          </div>
        )}

        {paymentMethod === 'bankTransfer' && (
          <div className="bg-gray-50 p-4 rounded-md">
            <p className="text-gray-700">
              Bank transfer details will be provided after you submit your order.
            </p>
          </div>
        )}

        {error && (
          <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm text-red-700">{error}</p>
              </div>
            </div>
          </div>
        )}

        <div className="flex justify-between pt-4">
          <button
            type="button"
            onClick={onBack}
            className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-custom-red"
          >
            Back
          </button>
          <button
            type="submit"
            disabled={isSubmitting}
            className={`
              px-6 py-2 bg-custom-red text-white rounded-md
              ${isSubmitting ? 'opacity-50 cursor-not-allowed' : 'hover:bg-red-600'}
              focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-red
              transition-colors duration-200
            `}
          >
            {isSubmitting ? (
              <span className="flex items-center">
                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Processing...
              </span>
            ) : (
              'Complete Payment'
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PaymentStep;