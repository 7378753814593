import React from 'react';
import { Link } from 'react-router-dom';
import PageHeader from "../component/PageHeader";
import Footer from "../component/Footer";
import exhibitBgImage from "/exhibition.jpg";
import { Icon } from '@iconify/react';

const categories = [
  {
    title: "Financiers",
    icon: "mdi:finance",
    items: [
      "Venture Capitalists",
      "Angel Investors",
      "Film Financing Institutions",
      "Financial institutions",
      "Brands that supports films",
      "Investors from other industries e.g. Oil & Gas, Insurance Companies etc.",
      "Private Equity Firms",
      "Government Agencies and Grants Bodies",
      "Film and Media Investment Funds"
    ]
  },
  {
    title: "Film Industry Professionals",
    icon: "mdi:movie-open",
    items: [
      "Film Producers",
      "Film Directors",
      "Screenwriters",
      "Film Distributors",
      "Film Sales Agents",
      "Film Production Companies",
      "Film Marketing and PR Professionals",
      "Film Exhibitors and Theaters"
    ]
  },
  {
    title: "Industry Partners",
    icon: "mdi:handshake",
    items: [
      "Film and Media Associations",
      "Film Studios",
      "Media Companies",
      "Creative Agencies",
      "Technology Providers for Film Production and Distribution"
    ]
  },
  {
    title: "Media Partners",
    icon: "mdi:newspaper",
    items: [
      "Journalists and Film Critics",
      "Film Bloggers and Influencers",
      "Media Outlets (TV, Radio, Online Platforms)"
    ]
  },
  {
    title: "Event Participants",
    icon: "mdi:account-group",
    items: [
      "Attendees of Pitch Sessions",
      "Participants in Masterclasses",
      "Visitors to Exhibitions and Expos",
      "Delegates at Networking Sessions"
    ]
  },
  {
    title: "Sponsorship and Marketing Representatives",
    icon: "mdi:marketing",
    items: [
      "Corporate Sponsors",
      "Marketing and Advertising Agencies"
    ]
  },
  {
    title: "Educational and Training Institutions",
    icon: "mdi:school",
    items: [
      "Film Schools and Universities",
      "Professional Training Organizations in Film and Media"
    ]
  },
  {
    title: "Government, Tourist sites and Policy Makers",
    icon: "mdi:government",
    items: [
      "Representatives from Film Commissions",
      "Policy Makers in Media and Culture",
      "Tourist Sites (CineTourExpo)"
    ]
  },
  {
    title: "General Public Interested in Film",
    icon: "mdi:account-heart",
    items: [
      "Film Enthusiasts",
      "Students and Academics in Film Studies"
    ]
  }
];

const WhoCanExhibitPage = () => {
  return (
    <div className="relative">
      <PageHeader 
        title="WHO CAN EXHIBIT" 
        description="Discover the diverse range of industries and professionals who can showcase their work at AFFF."
        backgroundImage={exhibitBgImage}
      />
      <main className="bg-white mb-12 sm:mb-24 md:mb-48 p-4 sm:p-6">
        <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-8">
          <section className="mb-8 sm:mb-12 font-lexend">
            <p className="mb-8 text-sm sm:text-base text-center max-w-3xl mx-auto">
              The AFFF event focuses on the African film industry but welcomes participation from all sectors.
              Over the course of three days, with an audience of more than 3,000 attendees, a diverse range of
              industries can showcase their work. Below is a list of some of the types of exhibitors who can
              participate:
            </p>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {categories.map((category, index) => {
                const hue = 30 + (index * 25) % 360; // Start from orange (30) and cycle through hues
                const bgColor = `hsl(${hue}, 70%, 90%)`;
                const borderColor = `hsl(${hue}, 70%, 70%)`;
                const itemBgColor = `hsl(${hue}, 70%, 95%)`;
                
                return (
                  <div 
                    key={category.title} 
                    className="rounded-lg p-6 shadow-lg transition-all duration-300 hover:shadow-xl"
                    style={{ backgroundColor: bgColor, borderLeft: `4px solid ${borderColor}` }}
                  >
                    <div className="flex items-center mb-4">
                      <Icon icon={category.icon} className="text-[3rem] mr-3" style={{ color: borderColor }} />
                      <h3 className="font-header text-[2rem] leading-none tracking-wider font-bold">{category.title}</h3>
                    </div>
                    <ul className="space-y-2">
                      {category.items.map((item, itemIndex) => (
                        <li key={itemIndex}>
                          <div 
                            className="p-2 pl-3 rounded shadow-md"
                            style={{ backgroundColor: itemBgColor }}
                          >
                            {item}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                );
              })}
            </div>

            <p className="mt-12 text-center font-bold text-lg">
              TO EXHIBIT CONTACT US AT{' '}
              <a href="mailto:project@africafilmfinanceforum.com" className="text-orange-600 hover:underline">
                project@africafilmfinanceforum.com
              </a>
            </p>
            <Link 
                to="/exhibit/how-to-exhibit" 
                className="px-10 py-2 mt-4 mb-6 uppercase font-bold cursor-pointer bg-custom-red rounded-tl-lg rounded-br-lg text-white font-roboto text-[18px] inline-block"
              >
              How To Exhibit
          </Link>
          </section>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default WhoCanExhibitPage;