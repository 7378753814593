// PersonalInfoForm.jsx
import React, { useState } from 'react';
import { countries, organizationSizes, industries, jobTitles } from './constants';

const API_URL = 'https://updates.mtnasap.cloud/afff/api';

const PersonalInfoForm = ({ cart, onBack, onSubmit }) => {
  const [activeSection, setActiveSection] = useState('personal'); // personal, billing, badges
  const [formData, setFormData] = useState({
    // Personal Info
    firstName: '',
    lastName: '',
    email: '',
    gender: '',
    company: '',
    organizationSize: '',
    industry: '',
    title: '',
    streetAddress: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    mobile: '',
    agreeTerms: false,
    agreeSponsors: false,
    
    // Billing Info
    billingInfo: {
      firstName: '',
      lastName: '',
      email: '',
      streetAddress: '',
      city: '',
      state: '',
      country: '',
      zipCode: '',
      mobile: '',
    },

    // Badge Info
    badges: cart.map(() => ({
      firstName: '',
      lastName: '',
      email: '',
      mobile: '',
      company: '',
      position: '',
      streetAddress: '',
      city: '',
      state: '',
      country: '',
      nameOfBadge: '',
      isPlaceholder: false,
      placeholderName: '',
    })),
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const validatePhone = (phone) => {
    return /^\+?[\d\s-]{10,}$/.test(phone);
  };

  const validateForm = () => {
    const errors = {};
    
    // Personal Information Validation
    if (!formData.firstName.trim()) errors.firstName = "First name is required";
    if (!formData.lastName.trim()) errors.lastName = "Last name is required";
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!validateEmail(formData.email)) {
      errors.email = "Invalid email format";
    }
    if (!formData.mobile.trim()) {
      errors.mobile = "Mobile number is required";
    } else if (!validatePhone(formData.mobile)) {
      errors.mobile = "Invalid phone number format";
    }
    if (!formData.company.trim()) errors.company = "Company name is required";
    if (!formData.country.trim()) errors.country = "Country is required";

    // Billing Information Validation
    const billingKeys = ['firstName', 'lastName', 'email', 'streetAddress', 'city', 'country', 'zipCode', 'mobile'];
    billingKeys.forEach(key => {
      if (!formData.billingInfo[key].trim()) {
        errors[`billing_${key}`] = `Billing ${key.replace(/([A-Z])/g, ' $1').toLowerCase()} is required`;
      }
    });
    if (formData.billingInfo.email && !validateEmail(formData.billingInfo.email)) {
      errors.billing_email = "Invalid billing email format";
    }
    if (formData.billingInfo.mobile && !validatePhone(formData.billingInfo.mobile)) {
      errors.billing_mobile = "Invalid billing phone number format";
    }

    // Badge Information Validation
    formData.badges.forEach((badge, index) => {
      if (!badge.isPlaceholder) {
        if (!badge.firstName.trim()) errors[`badge${index}_firstName`] = "First name is required";
        if (!badge.lastName.trim()) errors[`badge${index}_lastName`] = "Last name is required";
        if (!badge.email.trim()) {
          errors[`badge${index}_email`] = "Email is required";
        } else if (!validateEmail(badge.email)) {
          errors[`badge${index}_email`] = "Invalid email format";
        }
        if (!badge.company.trim()) errors[`badge${index}_company`] = "Company is required";
        if (!badge.position.trim()) errors[`badge${index}_position`] = "Position is required";
      } else {
        if (!badge.placeholderName.trim()) {
          errors[`badge${index}_placeholder`] = "Placeholder name is required";
        }
      }
    });

    // Terms Agreement Validation
    if (!formData.agreeTerms) {
      errors.agreeTerms = "You must agree to the terms and conditions";
    }

    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e, section = 'personal', index = null) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => {
      if (section === 'personal') {
        return { ...prev, [name]: type === 'checkbox' ? checked : value };
      }
      if (section === 'billing') {
        return {
          ...prev,
          billingInfo: { ...prev.billingInfo, [name]: value }
        };
      }
      if (section === 'badge' && index !== null) {
        const newBadges = [...prev.badges];
        newBadges[index] = {
          ...newBadges[index],
          [name]: type === 'checkbox' ? checked : value
        };
        return { ...prev, badges: newBadges };
      }
      return prev;
    });

    // Clear error for the changed field
    if (fieldErrors[name]) {
      setFieldErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  const copyPersonalToBilling = () => {
    setFormData(prev => ({
      ...prev,
      billingInfo: {
        firstName: prev.firstName,
        lastName: prev.lastName,
        email: prev.email,
        streetAddress: prev.streetAddress,
        city: prev.city,
        state: prev.state,
        country: prev.country,
        zipCode: prev.zipCode,
        mobile: prev.mobile,
      }
    }));
  };

  const copyInfoToBadge = (index, source = 'personal') => {
    setFormData(prev => {
      const newBadges = [...prev.badges];
      const sourceData = source === 'personal' ? prev : prev.billingInfo;
      newBadges[index] = {
        ...newBadges[index],
        firstName: sourceData.firstName,
        lastName: sourceData.lastName,
        email: sourceData.email,
        mobile: sourceData.mobile,
        company: prev.company,
        position: prev.title,
        streetAddress: sourceData.streetAddress,
        city: sourceData.city,
        state: sourceData.state,
        country: sourceData.country,
      };
      return { ...prev, badges: newBadges };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      setError("Please correct all errors before submitting.");
      return;
    }

    setIsSubmitting(true);
    setError('');

    try {
      const response = await fetch(`${API_URL}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          personalInfo: formData,
          cart: cart
        }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Registration failed');
      }

      if (data.success) {
        onSubmit(formData);
      } else {
        throw new Error(data.message || 'Registration failed');
      }
    } catch (error) {
      console.error('Registration error:', error);
      setError(error.message || 'An error occurred during registration. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderField = ({
    name,
    label,
    type = 'text',
    required = true,
    placeholder = '',
    options = null,
    section = 'personal',
    index = null,
    className = ''
  }) => {
    const fieldName = section === 'badge' ? `badge${index}_${name}` : section === 'billing' ? `billing_${name}` : name;
    const value = section === 'badge' 
      ? formData.badges[index][name]
      : section === 'billing'
      ? formData.billingInfo[name]
      : formData[name];

    return (
      <div className={`flex flex-col ${className}`}>
        <label 
          htmlFor={fieldName} 
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          {label}
          {required && <span className="text-red-500 ml-1">*</span>}
        </label>
        
        {type === 'select' ? (
          <select
            id={fieldName}
            name={name}
            value={value}
            onChange={(e) => handleChange(e, section, index)}
            className={`
              form-select rounded-md shadow-sm
              ${fieldErrors[fieldName] ? 'border-red-500' : 'border-gray-300'}
              focus:ring-custom-red focus:border-custom-red
            `}
            required={required}
          >
            <option value="">{placeholder}</option>
            {options?.map(option => (
              <option 
                key={option.value || option} 
                value={option.value || option}
              >
                {option.label || option}
              </option>
            ))}
          </select>
        ) : type === 'textarea' ? (
          <textarea
            id={fieldName}
            name={name}
            value={value}
            onChange={(e) => handleChange(e, section, index)}
            className={`
              form-textarea rounded-md shadow-sm
              ${fieldErrors[fieldName] ? 'border-red-500' : 'border-gray-300'}
              focus:ring-custom-red focus:border-custom-red
            `}
            placeholder={placeholder}
            required={required}
            rows={4}
          />
        ) : (
          <input
            type={type}
            id={fieldName}
            name={name}
            value={value}
            onChange={(e) => handleChange(e, section, index)}
            className={`
              form-input rounded-md shadow-sm
              ${fieldErrors[fieldName] ? 'border-red-500' : 'border-gray-300'}
              focus:ring-custom-red focus:border-custom-red
            `}
            placeholder={placeholder}
            required={required}
          />
        )}
        
        {fieldErrors[fieldName] && (
          <p className="mt-1 text-sm text-red-600">{fieldErrors[fieldName]}</p>
        )}
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-8 p-6 font-lexend">
      {/* Navigation Tabs */}
      <div className="border-b border-gray-200">
        <nav className="flex -mb-px">
          {['personal', 'billing', 'badges'].map((section) => (
            <button
              key={section}
              type="button"
              onClick={() => setActiveSection(section)}
              className={`
                mr-8 py-4 px-1 border-b-2 font-medium text-sm
                ${activeSection === section
                  ? 'border-custom-red text-custom-red'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}
              `}
            >
              {section.charAt(0).toUpperCase() + section.slice(1)} Information
            </button>
          ))}
        </nav>
      </div>

      {/* Personal Information Section */}
      {activeSection === 'personal' && (
        <div className="bg-white rounded-lg shadow-sm p-6">
          <h3 className="text-xl font-bold mb-6 text-gray-900">Personal Information</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {renderField({
              name: 'firstName',
              label: 'First Name',
              placeholder: 'Enter your first name'
            })}
            {renderField({
              name: 'lastName',
              label: 'Last Name',
              placeholder: 'Enter your last name'
            })}
            {renderField({
              name: 'email',
              label: 'Email Address',
              type: 'email',
              placeholder: 'Enter your email address'
            })}
            {renderField({
              name: 'gender',
              label: 'Gender',
              type: 'select',
              options: [
                { value: 'male', label: 'Male' },
                { value: 'female', label: 'Female' },
                { value: 'other', label: 'Other' }
              ],
              placeholder: 'Select your gender'
            })}
            {renderField({
              name: 'company',
              label: 'Company',
              placeholder: 'Enter your company name'
            })}
            {renderField({
              name: 'organizationSize',
              label: 'Organization Size',
              type: 'select',
              options: organizationSizes,
              placeholder: 'Select organization size'
            })}
            {renderField({
              name: 'industry',
              label: 'Industry',
              type: 'select',
              options: industries,
              placeholder: 'Select your industry'
            })}
            {renderField({
              name: 'title',
              label: 'Job Title',
              type: 'select',
              options: jobTitles,
              placeholder: 'Select your job title'
            })}
            {renderField({
              name: 'streetAddress',
              label: 'Street Address',
              placeholder: 'Enter your street address',
              className: 'col-span-full'
            })}
            {renderField({
              name: 'city',
              label: 'City',
              placeholder: 'Enter your city'
            })}
            {renderField({
              name: 'state',
              label: 'State/Province',
              placeholder: 'Enter your state/province'
            })}
            {renderField({
              name: 'country',
              label: 'Country',
              type: 'select',
              options: countries,
              placeholder: 'Select your country'
            })}
            {renderField({
              name: 'zipCode',
              label: 'ZIP/Post Code',
              placeholder: 'Enter your ZIP/post code'
            })}
            {renderField({
              name: 'mobile',
              label: 'Mobile Number',
              type: 'tel',
              placeholder: 'Enter your mobile number'
            })}
          </div>
        </div>
      )}

      {/* Billing Information Section */}
      {activeSection === 'billing' && (
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-xl font-bold text-gray-900">Billing Information</h3>
            <button
              type="button"
              onClick={copyPersonalToBilling}
              className="text-custom-red hover:text-red-700 text-sm font-medium flex items-center"
            >
              <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
              </svg>
              Copy from Personal Information
            </button>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {renderField({
              name: 'firstName',
              label: 'First Name',
              placeholder: 'Enter billing first name',
              section: 'billing'
            })}
            {renderField({
              name: 'lastName',
              label: 'Last Name',
              placeholder: 'Enter billing last name',
              section: 'billing'
            })}
            {renderField({
              name: 'email',
              label: 'Email Address',
              type: 'email',
              placeholder: 'Enter billing email address',
              section: 'billing'
            })}
            {renderField({
              name: 'streetAddress',
              label: 'Street Address',
              placeholder: 'Enter billing street address',
              section: 'billing',
              className: 'col-span-full'
            })}
            {renderField({
              name: 'city',
              label: 'City',
              placeholder: 'Enter billing city',
              section: 'billing'
            })}
            {renderField({
              name: 'state',
              label: 'State/Province',
              placeholder: 'Enter billing state/province',
              section: 'billing'
            })}
            {renderField({
              name: 'country',
              label: 'Country',
              type: 'select',
              options: countries,
              placeholder: 'Select billing country',
              section: 'billing'
            })}
            {renderField({
              name: 'zipCode',
              label: 'ZIP/Post Code',
              placeholder: 'Enter billing ZIP/post code',
              section: 'billing'
            })}
            {renderField({
              name: 'mobile',
              label: 'Mobile Number',
              type: 'tel',
              placeholder: 'Enter billing mobile number',
              section: 'billing'
            })}
          </div>
        </div>
      )}

      {/* Badges Section */}
      {activeSection === 'badges' && (
        <div className="space-y-8">
          {formData.badges.map((badge, index) => (
            <div key={index} className="bg-white rounded-lg shadow-sm p-6">
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-xl font-bold text-gray-900">
                  Badge {index + 1} - {cart[index].name}
                </h3>
                <div className="flex space-x-4">
                  <button
                    type="button"
                    onClick={() => copyInfoToBadge(index, 'personal')}
                    className="text-custom-red hover:text-red-700 text-sm font-medium flex items-center"
                  >
                    <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                    </svg>
                    Copy from Personal
                  </button>
                  <button
                    type="button"
                    onClick={() => copyInfoToBadge(index, 'billing')}
                    className="text-custom-red hover:text-red-700 text-sm font-medium flex items-center"
                  >
                    <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />
                    </svg>
                    Copy from Billing
                  </button>
                </div>
              </div>

              <div className="space-y-6">
                <div className="flex items-center mb-4">
                  <input
                    type="checkbox"
                    id={`isPlaceholder-${index}`}
                    name="isPlaceholder"
                    checked={badge.isPlaceholder}
                    onChange={(e) => handleChange(e, 'badge', index)}
                    className="h-4 w-4 text-custom-red focus:ring-custom-red border-gray-300 rounded"
                  />
                  <label htmlFor={`isPlaceholder-${index}`} className="ml-2 block text-sm text-gray-700">
                    This is a placeholder badge (details to be provided later)
                  </label>
                </div>

                {badge.isPlaceholder ? (
                  <div className="space-y-4">
                    {renderField({
                      name: 'placeholderName',
                      label: 'Placeholder Name/Reference',
                      placeholder: 'Enter a reference name for this badge',
                      section: 'badge',
                      index
                    })}
                  </div>
                ) : (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {renderField({
                      name: 'firstName',
                      label: 'First Name',
                      placeholder: 'Enter badge holder first name',
                      section: 'badge',
                      index
                    })}
                    {renderField({
                      name: 'lastName',
                      label: 'Last Name',
                      placeholder: 'Enter badge holder last name',
                      section: 'badge',
                      index
                    })}
                    {renderField({
                      name: 'email',
                      label: 'Email Address',
                      type: 'email',
                      placeholder: 'Enter badge holder email',
                      section: 'badge',
                      index
                    })}
                    {renderField({
                      name: 'mobile',
                      label: 'Mobile Number',
                      type: 'tel',
                      placeholder: 'Enter badge holder mobile number',
                      section: 'badge',
                      index
                    })}
                    {renderField({
                      name: 'company',
                      label: 'Company',
                      placeholder: 'Enter company name',
                      section: 'badge',
                      index
                    })}
                    {renderField({
                      name: 'position',
                      label: 'Position',
                      placeholder: 'Enter job position',
                      section: 'badge',
                      index
                    })}
                  </div>
                )}

                {/* Badge Preview */}
                <div className="mt-6">
                  <h4 className="text-lg font-semibold mb-4">Badge Preview</h4>
                  <div 
                    className="border rounded-lg p-6 relative bg-contain bg-center bg-no-repeat"
                    style={{ 
                      backgroundImage: `url('/AFFF-badge.png')`,
                      width: '300px',
                      height: '400px'
                    }}
                  >
                    <div className="absolute inset-0 flex flex-col items-center justify-center text-center">
                      <p className="font-semibold text-xl mb-2">
                        {badge.isPlaceholder 
                          ? badge.placeholderName 
                          : `${badge.firstName} ${badge.lastName}`}
                      </p>
                      <p className="text-gray-600 italic mb-2">{badge.position}</p>
                      <p className="font-bold text-custom-red">{badge.company}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Terms and Agreements */}
      <div className="space-y-4 bg-gray-50 p-6 rounded-lg">
        <div className="flex items-center">
          <input
            type="checkbox"
            id="agreeSponsors"
            name="agreeSponsors"
            checked={formData.agreeSponsors}
            onChange={(e) => handleChange(e)}
            className="h-4 w-4 text-custom-red focus:ring-custom-red border-gray-300 rounded"
          />
          <label htmlFor="agreeSponsors" className="ml-2 block text-sm text-gray-700">
            I agree to receive communications from event sponsors (optional)
          </label>
        </div>

        <div className="flex items-start">
          <div className="flex items-center h-5">
            <input
              type="checkbox"
              id="agreeTerms"
              name="agreeTerms"
              checked={formData.agreeTerms}
              onChange={(e) => handleChange(e)}
              className="h-4 w-4 text-custom-red focus:ring-custom-red border-gray-300 rounded"
              required
            />
          </div>
          <label htmlFor="agreeTerms" className="ml-2 text-sm text-gray-700">
            I agree to the{' '}
            <a href="/terms" className="text-custom-red hover:text-red-700" target="_blank" rel="noopener noreferrer">
              Terms and Conditions
            </a>
            {' '}of the AFFF SUMMIT 2025 Registration
            <span className="text-red-500 ml-1">*</span>
          </label>
        </div>
        {fieldErrors.agreeTerms && (
          <p className="mt-1 text-sm text-red-600">{fieldErrors.agreeTerms}</p>
        )}
      </div>

      {/* Form Actions */}
      <div className="flex justify-between pt-6 border-t border-gray-200">
        <button
          type="button"
          onClick={onBack}
          className="px-6 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-red"
          disabled={isSubmitting}
        >
          Back
        </button>
        <button
          type="submit"
          disabled={isSubmitting}
          className={`
            px-6 py-2 bg-custom-red text-white rounded-md
            ${isSubmitting ? 'opacity-50 cursor-not-allowed' : 'hover:bg-red-600'}
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-red
            transition-colors duration-200
          `}
        >
          {isSubmitting ? (
            <span className="flex items-center">
              <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Processing...
            </span>
          ) : (
            'Review Registration'
          )}
        </button>
      </div>

      {error && (
        <div className="mt-4 bg-red-50 border-l-4 border-red-500 p-4 rounded">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        </div>
      )}
    </form>
  );
};

export default PersonalInfoForm;