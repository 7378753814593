import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';

const CartSection = ({ cart, updateCart, removeFromCart, proceedToPersonalInfo, currency, convertCurrency }) => {
  const [promoCode, setPromoCode] = useState('');
  const [promoApplied, setPromoApplied] = useState(false);

  const subtotal = cart.reduce((sum, item) => sum + item.price * item.quantity, 0);
  const discount = promoApplied ? subtotal * 0.1 : 0; // 10% discount for example
  const total = subtotal - discount;

  const handleApplyPromo = () => {
    // Here you would typically validate the promo code with your backend
    if (promoCode === 'DISCOUNT10' || 'EARLYBIRD23') {
      setPromoApplied(true);
    }
  };

  return (
    <section id="cart" className="p-6 bg-white shadow-lg rounded-lg mb-8">
      <h2 className="text-2xl font-bold mb-4 font-roboto">Your Order</h2>
      {cart.length === 0 ? (
        <p className="text-gray-500 font-lexend">Your order is empty.</p>
      ) : (
        <>
          {cart.map((item, index) => (
            <div key={index} className="flex justify-between items-center mb-4 pb-4 border-b">
              <div>
                <h3 className="font-bold font-roboto">{item.name}</h3>
                <p className="font-lexend">{convertCurrency(item.price)}</p>
              </div>
              <div className="flex items-center">
                <button 
                  onClick={() => updateCart(item, -1)} 
                  className="bg-gray-200 px-2 py-1 rounded font-lexend"
                >
                  -
                </button>
                <span className="mx-2 font-lexend">{item.quantity}</span>
                <button 
                  onClick={() => updateCart(item, 1)} 
                  className="bg-gray-200 px-2 py-1 rounded font-lexend"
                >
                  +
                </button>
                <button 
                  onClick={() => removeFromCart(item)} 
                  className="ml-4 text-red-500"
                >
                  <FaTimes />
                </button>
              </div>
            </div>
          ))}
          <div className="mb-4">
            <input
              type="text"
              placeholder="Promo Code"
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
              className="border p-2 mr-2 font-lexend"
            />
            <button 
              onClick={handleApplyPromo}
              className="px-6 py-2 cursor-pointer rounded-tl-lg rounded-br-lg hover:border border-orange-500 tracking-wide text-[18px] bg-custom-red hover:transition-all text-white font-lexend"
            >
              Apply
            </button>
          </div>
          <div className="mb-4">
            <div className="flex justify-between mb-2 font-lexend">
              <span>Subtotal:</span>
              <span>{convertCurrency(subtotal)}</span>
            </div>
            {promoApplied && (
              <div className="flex justify-between mb-2 text-green-500 font-lexend">
                <span>Discount:</span>
                <span>-{convertCurrency(discount)}</span>
              </div>
            )}
            <div className="flex justify-between font-bold font-lexend">
              <span>Total:</span>
              <span>{convertCurrency(total)}</span>
            </div>
          </div>
          <button 
            onClick={proceedToPersonalInfo} 
            className="w-full px-6 py-2 cursor-pointer rounded-tl-lg rounded-br-lg hover:border border-orange-500 tracking-wide text-[18px] bg-custom-red hover:transition-all text-white font-lexend"
          >
            Proceed to Registration Information
          </button>
        </>
      )}
    </section>
  );
};

export default CartSection;