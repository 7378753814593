import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { FaHourglass, FaArrowLeft } from 'react-icons/fa';
import PageHeader from "../component/PageHeader";
import Footer from "../component/Footer";
import BadgesSection from '../component/BadgesSection';
import CartSection from '../component/CartSection';
import PersonalInfoForm from '../component/PersonalInfoForm';
import PaymentStep from '../component/PaymentStep';
import registrationBgImage from "/pages-bg/8.png";

const RegistrationPage = () => {
  const [expandedFaq, setExpandedFaq] = useState(null);
  const [cart, setCart] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isCartVisible, setIsCartVisible] = useState(true);
  const [showPersonalInfo, setShowPersonalInfo] = useState(false);
  const [showPaymentStep, setShowPaymentStep] = useState(false);
  const [registrationData, setRegistrationData] = useState(null);
  const [showFeaturesComparison, setShowFeaturesComparison] = useState(null);
  const [currency, setCurrency] = useState({ code: 'USD', symbol: '$' });
  const [timeLeft, setTimeLeft] = useState(30 * 60); // 30 minutes in seconds
  const [exchangeRate, setExchangeRate] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState("");
  const [subscriptionSuccess, setSubscriptionSuccess] = useState("");

  useEffect(() => {
    const fetchUserLocationAndExchangeRate = async () => {
      try {
        const locationResponse = await fetch('https://ipapi.co/json/');
        const locationData = await locationResponse.json();
        
        let currencyCode = 'USD';
        let currencySymbol = '$';

        if (locationData.country_code === 'NG') {
          currencyCode = 'NGN';
          currencySymbol = '₦';
        } else if (locationData.country_code === 'GB') {
          currencyCode = 'GBP';
          currencySymbol = '£';
        } else if (locationData.currency === 'EUR') {
          currencyCode = 'EUR';
          currencySymbol = '€';
        }

        setCurrency({ code: currencyCode, symbol: currencySymbol });

        if (currencyCode !== 'USD') {
          const rateResponse = await fetch(`https://api.exchangerate-api.com/v4/latest/USD`);
          const rateData = await rateResponse.json();
          setExchangeRate(rateData.rates[currencyCode]);
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load exchange rates. Using default conversions.');
        setLoading(false);
      }
    };

    fetchUserLocationAndExchangeRate();

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const convertCurrency = (priceUSD) => {
    if (loading) return 'Loading...';
    if (error) return `${currency.symbol}${priceUSD.toLocaleString(undefined, {maximumFractionDigits: 2})}`;
    const convertedPrice = priceUSD * exchangeRate;
    return `${currency.symbol}${convertedPrice.toLocaleString(undefined, {maximumFractionDigits: 2})}`;
  };

  const toggleCartVisibility = () => {
    setIsCartVisible(!isCartVisible);
  };

  const toggleFaq = (index) => {
    setExpandedFaq(expandedFaq === index ? null : index);
  };

  const toggleFeaturesComparison = (category) => {
    setShowFeaturesComparison(prevState => prevState === category ? null : category);
  };

  const addToCart = (badge) => {
    setCart(prevCart => {
      const existingItem = prevCart.find(item => item.name === badge.name);
      if (existingItem) {
        return prevCart.map(item => 
          item.name === badge.name ? {...item, quantity: item.quantity + 1} : item
        );
      } else {
        return [...prevCart, {...badge, quantity: 1}];
      }
    });
    setShowModal(true);
  };

  const updateCart = (item, change) => {
    setCart(prevCart => prevCart.map(
      cartItem => cartItem.name === item.name 
        ? {...cartItem, quantity: Math.max(0, cartItem.quantity + change)} 
        : cartItem
    ).filter(item => item.quantity > 0));
  };

  const removeFromCart = (item) => {
    setCart(prevCart => prevCart.filter(cartItem => cartItem.name !== item.name));
  };

  const proceedToPersonalInfo = () => {
    setShowPersonalInfo(true);
    setTimeLeft(30 * 60); // Reset timer to 30 minutes
  };

  const handlePersonalInfoSubmit = (formData) => {
    setRegistrationData({ ...formData, cart });
    setShowPaymentStep(true);
  };

  const handlePaymentStepBack = () => {
    setShowPaymentStep(false);
  };

  const handlePaymentSubmit = (paymentData) => {
    console.log('Payment submitted:', paymentData);
    // Handle payment submission logic here
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleSubscribe = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubscriptionError("");
    setSubscriptionSuccess("");

    try {
      const response = await axios.post(`https://api.africafilmfinanceforum.com/subscribe`, { email });
      setSubscriptionSuccess("You're In! We'll keep you updated!");
      setEmail("");
    } catch (error) {
      setSubscriptionError("Subscription failed. Please try again later.");
      console.error('Newsletter subscription error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const attendeeCategories = [
    {
      title: "FINANCIERS",
      items: ["Venture Capitalists", "Angel Investors", "Film Financing Institutions", "Financial Institutions", "Brands that Support Films", "Investors from Other Industries", "Private Equity Firms", "Government Agencies and Grants Bodies", "Film and Media Investment Funds"]
    },
    {
      title: "INDUSTRY PROFESSIONALS",
      items: ["Film Producers", "Film Directors", "Screenwriters", "Film Distributors", "Film Sales Agents", "Film Production Companies", "Film Marketing and PR Professionals", "Film Exhibitors and Theaters", "Cinematographers", "Film Editors", "Sound Designers", "Visual Effects Artists"]
    },
    {
      title: "INDUSTRY STAKEHOLDER & ENTHUSIASTS",
      items: ["Film and Media Associations", "Film Studios", "Media Companies", "Creative Agencies", "Technology Providers for Film Production", "Journalists and Film Critics", "Film Bloggers and Influencers", "Media Outlets (TV, Radio, Online Platforms)", "Film Schools and Universities", "Policy Makers in Media and Culture", "Film Commissions Representatives", "Film Enthusiasts", "Students and Academics in Film Studies"]
    }
  ];

  const faqItems = [
    {
      question: "Registration",
      answer: (
        <>
          <p>Once you complete your registration to AFFF, you will receive a confirmation email containing a registration number. Keep this email handy as you may need it to make changes to your registration. You can also easily purchase all the passes you need in a single order or register for AFFF even if you don't know yet who from your team will be attending.</p>
          <ul className="list-disc pl-5 mt-2">
            <li><a href="#" className="text-custom-red hover:underline">Find your registration number</a></li>
            <li><a href="#" className="text-custom-red hover:underline">Registering a group</a></li>
            <li><a href="#" className="text-custom-red hover:underline">Registering with placeholder attendees</a></li>
          </ul>
        </>
      )
    },
    {
      question: "Transferring and canceling a pass",
      answer: (
        <>
          <p>We understand life happens. You can transfer your Africa Film Finance Forum pass to someone at any time prior to the event. You can get a transfer your pass until November 10, 2024. After November 10, 2024, we will won't be able to provide help with pass transfers.</p>
          <ul className="list-disc pl-5 mt-2">
            <li><a href="#" className="text-custom-red hover:underline" target="_blank">Transfer your pass</a></li>
          </ul>
        </>
      )
    },
    {
      question: "Need help with your purchase?",
      answer: (
        <>
          <p>If you need help with your purchase or are eligible for a discount, just get in touch with us by replying to your registration confirmation email or emailing us at help@africafilmfinanceforum.com. We will be happy to assist you.</p>
          <a href="#" className="text-custom-red hover:underline" target="_blank">Are you eligible for a discount?</a>
        </>
      )
    }
  ];

  return (
    <div className="relative bg-[#FAF0E6]">
      <PageHeader 
        title="REGISTRATION" 
        description="Join the Africa Film Finance Forum and be part of the revolution in African cinema."
        backgroundImage={registrationBgImage}
      />
      <main>
        <div className="container mx-auto px-4 sm:px-6 py-8 sm:py-12">
          <section className="mb-16 text-[1.1rem] border-y-3 ">
            <div className="border-l-4 sm:border-l-8 border-[#A18749] pl-2 sm:pl-4 mb-4">
              <h2 className="font-header text-4xl sm:text-5xl font-bold mb-6 tracking-wider text-custom-base">DON'T MISS THE AFRICA FILM FINANCE: REGISTER NOW!</h2>
            </div>
            <p className="mb-4">
              The Africa Film Finance Forum (AFFF) is transforming African cinema by connecting visionary filmmakers with forward-thinking financiers. It fosters collaboration, growth, and success by uniting industry professionals and offering filmmakers the resources they need while providing financiers with high-potential projects. Join AFFF to be part of a revolution in African storytelling and seize the next big opportunity.            
            </p>
          </section>

          <section className="mb-16">
            <h2 className="font-header text-4xl sm:text-5xl font-bold mb-6 tracking-wider text-custom-base">Who Should Attend?</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {attendeeCategories.map((category, index) => (
                <div key={index} className="bg-white rounded-md shadow-md overflow-hidden border border-gray-300">
                  <h3 className="font-roboto text-2xl font-bold p-4 bg-gray-100 text-custom-base">{category.title}</h3>
                  <ul className="list-disc pl-8 pr-4 py-4 space-y-2 font-lexend">
                    {category.items.map((item, i) => (
                      <li key={i} className="text-sm sm:text-base">{item}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </section>

          <BadgesSection 
            addToCart={addToCart} 
            currency={currency}
            showFeaturesComparison={showFeaturesComparison}
            toggleFeaturesComparison={toggleFeaturesComparison}
            convertCurrency={convertCurrency}
          />

          <section className="w-full bg-white py-16">
            <div className="container mx-auto px-4 sm:px-6">
              <div className="flex justify-between items-center mb-8">
                <h2 className="font-header text-5xl sm:text-5xl font-bold tracking-wider text-custom-base pb-6">REGISTRATION FAQS</h2>
                <Link to="/help-center" className="border-2 border-custom-base text-custom-base px-4 py-2 font-bold hover:bg-custom-base hover:text-white transition duration-300">
                  SUPPORT CENTER
                </Link>
              </div>
              <div className="max-w-4xl mx-auto mt-6">
                {faqItems.map((item, index) => (
                  <div key={index} className="mb-4 border border-[#FDF6EC]">
                    <button
                      className="w-full text-left p-4 bg-[#FDF6EC] hover:bg-[#FCF3E7] transition-colors duration-300 font-lexend font-bold flex justify-between items-center"
                      onClick={() => toggleFaq(index)}
                    >
                      <h3 className="text-lg sm:text-xl">{item.question}</h3>
                      <span className="text-xl">{expandedFaq === index ? '×' : '+'}</span>
                    </button>
                    {expandedFaq === index && (
                      <div className="p-6 bg-[#FDF6EC]">
                        <div className="text-base sm:text-lg">{item.answer}</div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </section>

          <section className="bg-gradient-to-r from-[#f16764] to-[#FFA726] py-12">
            <div className="container mx-auto px-4 sm:px-6">
              <div>
                <h2 className="font-header text-4xl sm:text-5xl font-bold mb-4 text-white tracking-wider">STAY UPDATED ON AFFF ANNOUNCEMENTS</h2>
                <p className="font-lexend text-white text-xl mb-8">Don't miss out important AFFF updates and announcements. Join our 2024 mailing list</p>
              </div>
              <div>
              <form onSubmit={handleSubscribe} className="flex flex-col sm:flex-row gap-4 mb-4">
                  <input
                    type="email"
                    placeholder="Enter Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="flex-grow p-3 rounded-none font-lexend text-lg border-2 border-white bg-transparent text-white placeholder-white"
                    required
                  />
                  <button 
                    type="submit" 
                    disabled={isSubmitting}
                    className={`bg-white text-custom-red px-8 py-3 font-bold hover:bg-gray-100 transition duration-300 font-lexend text-lg ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                  >
                    {isSubmitting ? 'JOINING...' : 'JOIN THE LIST'}
                  </button>
                </form>
                {subscriptionError && <p className="text-red-200 text-sm mb-2">{subscriptionError}</p>}
                {subscriptionSuccess && <p className="text-green-200 text-sm mb-2">{subscriptionSuccess}</p>}
              </div>
              <p className="font-lexend text-white text-sm">
                We're committed to your privacy. AFFF uses the information you provide to contact you about AFFF content and events. You may unsubscribe from these communications at any time. For more information, check out our privacy policy.
              </p>
            </div>
          </section>
        </div>
      </main>

      {/* Cart Modal */}
      <div className={`fixed inset-0 bg-black bg-opacity-85 z-[3000] transition-opacity duration-300 ${showModal ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
        <div className={`fixed top-0 right-0 w-full sm:w-1/2 h-full bg-white bg-opacity-8 shadow-lg transition-transform duration-300 transform ${showModal ? 'translate-x-0' : 'translate-x-full'} overflow-y-auto`}>
        
          <div className="relative p-6">
          <button
              onClick={toggleCartVisibility}
              className="absolute top-1/2 -left-10 transform -translate-y-1/2 -rotate-90 ml-8 px-4 py-2 bg-custom-red shadow-md rounded-t-lg font-lexend"
            >
              {isCartVisible ? '' : ''}
            </button>
            
            <div className={`transition-transform duration-300 ${
              isCartVisible ? 'translate-x-0' : 'translate-x-full'
            }`}>
              <div className="absolute -left-[20px] top-0">
                <button 
                  onClick={() => setShowModal(false)} 
                  className="bg-orange-500 p-2 mx-1 my-1 w-[30px] h-[30px] flex items-center justify-center text-white"
                >
                  <FaArrowLeft size={24} />
                </button>
              </div>
              <CartSection 
                cart={cart}
                updateCart={updateCart}
                removeFromCart={removeFromCart}
                proceedToPersonalInfo={proceedToPersonalInfo}
                currency={currency}
                convertCurrency={convertCurrency}
              />
              {showPersonalInfo && !showPaymentStep && (
                <div>
                  <div className="bg-gray-100 p-4 flex justify-between items-center">
                    <h2 className="text-xl font-bold font-roboto">Registration Information</h2>
                    <div className="flex items-center bg-white px-3 py-1 rounded-full border-2 border-gray-300">
                      <FaHourglass className="mr-2 animate-spin" />
                      <div className="text-lg font-bold font-lexend">
                        {formatTime(timeLeft)}
                      </div>
                    </div>
                  </div>
                  {timeLeft > 0 ? (
                    <PersonalInfoForm 
                      cart={cart}
                      onBack={() => setShowPersonalInfo(false)}
                      onSubmit={handlePersonalInfoSubmit}
                    />
                  ) : (
                    <div className="p-4">
                      <p className="font-lexend">Your session has expired. Please start over.</p>
                      <button 
                        onClick={() => {
                          setShowPersonalInfo(false);
                          setTimeLeft(30 * 60);
                        }}
                        className="mt-4 px-6 py-2 cursor-pointer rounded-tl-lg rounded-br-lg hover:border border-orange-500 tracking-wide text-[18px] bg-custom-red hover:transition-all text-white font-lexend"
                      >
                        Start Over
                      </button>
                    </div>
                  )}
                </div>
              )}
              {showPaymentStep && (
                <PaymentStep 
                  amount={cart.reduce((sum, item) => sum + item.price * item.quantity, 0)}
                  onBack={handlePaymentStepBack}
                  onSubmit={handlePaymentSubmit}
                  currency={currency}
                  convertCurrency={convertCurrency}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default RegistrationPage;