// InvestorsRoomForm.jsx
import React, { useState } from 'react';
import BaseForm from './BaseForm';
import PaymentStep from './PaymentStep';

const API_URL = import.meta.env.VITE_API_URL;

const InvestorsRoomForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    companyName: '',
    contactName: '',
    contactDesignation: '',
    companyAddress: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    email: '',
    phone: '',
  });
  const [step, setStep] = useState(1);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\+?[\d\s-]{10,}$/;
    
    if (!formData.companyName.trim()) {
      setError("Company name is required");
      return false;
    }
    if (!formData.contactName.trim()) {
      setError("Contact name is required");
      return false;
    }
    if (!formData.email.trim() || !emailRegex.test(formData.email)) {
      setError("Please enter a valid email address");
      return false;
    }
    if (!formData.phone.trim() || !phoneRegex.test(formData.phone)) {
      setError("Please enter a valid phone number");
      return false;
    }
    return true;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError('');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setStep(2);
    }
  };

  const handlePaymentSubmit = async () => {
    setIsSubmitting(true);
    setError('');
    
    try {
      const response = await fetch(`${API_URL}/payments/initiate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: formData.email,
          amount: 1200,
          currency: 'USD',
          type: 'investorsRoom',
          metadata: formData
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      
      if (data.redirect_url) {
        window.location.href = data.redirect_url;
      } else {
        throw new Error('No redirect URL received');
      }
    } catch (error) {
      console.error('Payment initiation error:', error);
      setError('There was an error initiating the payment. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <BaseForm title="Own an Investors Room" onClose={onClose}>
      {step === 1 ? (
        <form onSubmit={handleSubmit} className="space-y-4">
          {Object.keys(formData).map((key) => (
            <div key={key}>
              <label htmlFor={key} className="block text-sm font-medium text-gray-700 mb-1 uppercase">
                {key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
                <span className="text-red-500">*</span>
              </label>
              <input
                type={key === 'email' ? 'email' : key === 'phone' ? 'tel' : 'text'}
                name={key}
                id={key}
                value={formData[key]}
                onChange={handleChange}
                className="form-input w-full focus:ring-2 focus:ring-custom-red"
                required
                placeholder={`Enter ${key.replace(/([A-Z])/g, ' $1').toLowerCase()}`}
              />
            </div>
          ))}

          {error && (
            <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded">
              <div className="flex">
                <div className="flex-shrink-0">
                  <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                  </svg>
                </div>
                <div className="ml-3">
                  <p className="text-sm text-red-700">{error}</p>
                </div>
              </div>
            </div>
          )}

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-custom-red"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-custom-red text-white px-4 py-2 rounded-md hover:bg-red-600 transition duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-red"
            >
              Next
            </button>
          </div>
        </form>
      ) : (
        <PaymentStep
          amount={1200}
          currency={{ code: 'USD', symbol: '$' }}
          onBack={() => setStep(1)}
          onSubmit={handlePaymentSubmit}
          isSubmitting={isSubmitting}
          error={error}
        />
      )}
    </BaseForm>
  );
};

export default InvestorsRoomForm;
