// FinancierAccreditationForm.jsx
import React, { useState } from 'react';
import BaseForm from './BaseForm';

const API_URL = 'https://updates.mtnasap.cloud/afff/api';

const FinancierAccreditationForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    companyName: '',
    financierName: '',
    contactDesignation: '',
    companyAddress: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    email: '',
    phone: ''
  });
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\+?[\d\s-]{10,}$/;

    if (!formData.companyName.trim()) {
      setError("Company name is required");
      return false;
    }
    if (!formData.financierName.trim()) {
      setError("Financier name is required");
      return false;
    }
    if (!formData.email.trim() || !emailRegex.test(formData.email)) {
      setError("Please enter a valid email address");
      return false;
    }
    if (!formData.phone.trim() || !phoneRegex.test(formData.phone)) {
      setError("Please enter a valid phone number");
      return false;
    }
    return true;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setError('');
    setSuccess('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    setIsSubmitting(true);
    setError('');
    setSuccess('');

    try {
      const response = await fetch(`${API_URL}/financier-accreditation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Submission failed');
      }

      setSuccess('Thank you for applying for Financier Accreditation. We will contact you with further details soon.');
      setFormData({
        companyName: '',
        financierName: '',
        contactDesignation: '',
        companyAddress: '',
        city: '',
        state: '',
        country: '',
        zipCode: '',
        email: '',
        phone: ''
      });

      setTimeout(() => onClose(), 3000);
    } catch (error) {
      console.error('Financier accreditation error:', error);
      setError(error.message || 'Accreditation submission failed. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const formFields = [
    { name: 'companyName', label: 'Company Name', type: 'text' },
    { name: 'financierName', label: 'Financier Name', type: 'text' },
    { name: 'contactDesignation', label: 'Contact Designation', type: 'text' },
    { name: 'companyAddress', label: 'Company Address', type: 'text' },
    { name: 'city', label: 'City', type: 'text' },
    { name: 'state', label: 'State', type: 'text' },
    { name: 'country', label: 'Country', type: 'text' },
    { name: 'zipCode', label: 'ZIP/Post Code', type: 'text' },
    { name: 'email', label: 'Email', type: 'email' },
    { name: 'phone', label: 'Phone', type: 'tel' }
  ];

  return (
    <BaseForm title="Financier Accreditation Form" onClose={onClose}>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="bg-gray-50 p-6 rounded-lg mb-6">
          <h3 className="text-lg font-semibold mb-4">Company Information</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {formFields.map(field => (
              <div key={field.name} className={field.name === 'companyAddress' ? 'md:col-span-2' : ''}>
                <label htmlFor={field.name} className="block text-sm font-medium text-gray-700 mb-1 uppercase">
                  {field.label} <span className="text-red-500">*</span>
                </label>
                <input
                  type={field.type}
                  name={field.name}
                  id={field.name}
                  value={formData[field.name]}
                  onChange={handleChange}
                  className="form-input w-full focus:ring-2 focus:ring-custom-red focus:border-transparent"
                  required
                  placeholder={`Enter ${field.label.toLowerCase()}`}
                />
              </div>
            ))}
          </div>
        </div>

        {error && (
          <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded">
            <p className="text-sm text-red-700">{error}</p>
          </div>
        )}

        {success && (
          <div className="bg-green-50 border-l-4 border-green-500 p-4 rounded">
            <p className="text-sm text-green-700">{success}</p>
          </div>
        )}

        <div className="flex justify-end space-x-4">
          <button type="button" onClick={onClose} className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-custom-red">
            Cancel
          </button>
          <button type="submit" disabled={isSubmitting} className={`px-6 py-2 bg-custom-red text-white rounded-md ${isSubmitting ? 'opacity-50 cursor-not-allowed' : 'hover:bg-red-600'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-red transition-colors duration-200`}>
            {isSubmitting ? 'Submitting...' : 'Submit Application'}
          </button>
        </div>
      </form>
    </BaseForm>
  );
};

export default FinancierAccreditationForm;
