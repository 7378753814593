import axios from 'axios';

const API_CONFIG = {
  baseURL: import.meta.env.VITE_API_URL || 'https://updates.mtnasap.cloud/afff/api',
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json'
  }
};

const api = axios.create(API_CONFIG);

export const initiatePayment = async (email, amount, currency = 'USD', type, metadata) => {
  try {
    const response = await api.post('/payments/initiate', {
      email,
      amount,
      currency,
      type,
      metadata: {
        ...metadata,
        badgeCount: metadata.badgeCount,
        isAnnual: metadata.isAnnual
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error initiating payment:', error);
    throw error;
  }
};

export const verifyPayment = async (reference) => {
  try {
    const response = await api.get(`/payments/verify/${reference}`);
    return response.data;
  } catch (error) {
    console.error('Error verifying payment:', error);
    throw error;
  }
};

export const submitContactForm = async (formData) => {
  try {
    const response = await api.post('/contact', formData);
    return response.data;
  } catch (error) {
    console.error('Error submitting contact form:', error);
    throw error;
  }
};

export default api;