import React, { useState } from 'react';
import PageHeader from "../component/PageHeader";
import Footer from "../component/Footer";
import venueBgImage from "/sponsorship.jpg";
import { ChevronLeft, ChevronRight, X } from 'lucide-react';

const VenuePage = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState({
    overview: 0,
    meetings: 0,
    lodging: 0,
    catering: 0
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [modalImages, setModalImages] = useState([]);
  const [modalCurrentIndex, setModalCurrentIndex] = useState(0);

  const venueCategories = [
    { title: "Venue Overview", folder: "overview" },
    { title: "Meetings", folder: "meetings" },
    { title: "Lodging", folder: "lodging" },
    { title: "Catering & Entertainment", folder: "catering" },
  ];

  const getImages = (folder) => {
    const context = import.meta.glob('/public/venue/**/*.{png,jpg,jpeg,webp}', { eager: true });
    return Object.keys(context)
      .filter(key => key.includes(`/venue/${folder}/`))
      .map(key => context[key].default);
  };

  const handlePrev = (category) => {
    setCurrentImageIndex(prev => ({
      ...prev,
      [category]: (prev[category] - 1 + getImages(category).length) % getImages(category).length
    }));
  };

  const handleNext = (category) => {
    setCurrentImageIndex(prev => ({
      ...prev,
      [category]: (prev[category] + 1) % getImages(category).length
    }));
  };

  const openModal = (category) => {
    setModalImages(getImages(category));
    setModalCurrentIndex(currentImageIndex[category]);
    setModalOpen(true);
  };

  const closeModal = () => setModalOpen(false);

  const modalPrev = () => {
    setModalCurrentIndex((prev) => (prev - 1 + modalImages.length) % modalImages.length);
  };

  const modalNext = () => {
    setModalCurrentIndex((prev) => (prev + 1) % modalImages.length);
  };

  return (
    <div className="relative">
      <PageHeader 
        title="THE VENUE" 
        description="The Lagos Oriental Hotel, Lagos, Nigeria"
        backgroundImage={venueBgImage}
      />
      <main className="bg-white mb-12 sm:mb-24 md:mb-48 p-4 sm:p-6">
        <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-8">
          <section className="mb-8 sm:mb-12 font-lexend">
            <h2 className="font-header text-5xl font-bold mb-4 tracking-wider text-custom-base">Our Venue</h2>
            <p className="mb-8 text-lg">
              On the banks of Five Cowries Creek overlooking Lagos Island, this upmarket hotel is 2 km from the white sands of Bar Beach, and 5 km from the Nigerian National Museum. Sleek, polished rooms come with Wi-Fi and flat-screen TVs, plus minifridges, and tea and coffeemaking facilities; some have water views. Upgraded rooms include sitting areas and safes, and some have minibars and separate living rooms. Room service is offered 24/7. There's a refined Asian restaurant, a coffee shop and a bar/lounge. Other amenities include a business centre, a gym, and an outdoor pool overlooking the creek.
            </p>
            
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
              {venueCategories.map(({ title, folder }) => (
                <div key={folder} className="div-with-bold-shadow p-4">
                  <h3 className="text-2xl font-bold mb-4">{title}</h3>
                  <div className="relative">
                    <img 
                      src={getImages(folder)[currentImageIndex[folder]]} 
                      alt={`${title} image`} 
                      className="w-full h-64 object-cover rounded-lg cursor-pointer"
                      onClick={() => openModal(folder)}
                    />
                    <button onClick={() => handlePrev(folder)} className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full">
                      <ChevronLeft />
                    </button>
                    <button onClick={() => handleNext(folder)} className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full">
                      <ChevronRight />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      </main>
      <Footer />

      {modalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative w-full max-w-4xl">
            <button onClick={closeModal} className="absolute top-4 right-4 text-white">
              <X size={24} />
            </button>
            <img 
              src={modalImages[modalCurrentIndex]} 
              alt="Venue detail" 
              className="w-full h-auto"
            />
            <button onClick={modalPrev} className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full">
              <ChevronLeft />
            </button>
            <button onClick={modalNext} className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 rounded-full">
              <ChevronRight />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default VenuePage;