import React, { useState, useEffect } from 'react';
import { ArrowLeft, ArrowRight, X } from 'lucide-react';
import { Link } from 'react-router-dom';

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-90 z-50 flex justify-center items-center">
      <div className="bg-white rounded-lg w-[70%] max-h-[650px] overflow-y-auto relative text-black">
        <button 
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
          <X size={24} />
        </button>
        <div className="p-8">
          {children}
        </div>
      </div>
    </div>
  );
};

const Announcement = ({ announcements = [] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (announcements.length === 0 || isModalOpen) return;

    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => 
        prevIndex === announcements.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => clearInterval(timer);
  }, [announcements.length, isModalOpen]);

  const nextAnnouncement = () => {
    if (announcements.length === 0) return;
    setCurrentIndex((prevIndex) => 
      prevIndex === announcements.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevAnnouncement = () => {
    if (announcements.length === 0) return;
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? announcements.length - 1 : prevIndex - 1
    );
  };

  if (announcements.length === 0) {
    return null;
  }

  const currentAnnouncement = announcements[currentIndex];

  return (
    <div className="relative bg-[#1e2833] text-white flex flex-col md:flex-row">
      <div className="w-full md:w-1/2">
        <img 
          src={currentAnnouncement.image} 
          alt="Announcement" 
          className="w-full h-64 md:h-full object-cover"
        />
      </div>
      <div className="w-full md:w-1/2 p-6 md:p-16 flex flex-col justify-between">
        <h2 className="font-header text-2xl md:text-[3rem] tracking-widest mb-4">ANNOUNCEMENTS</h2>
        <div>
          <h3 className="text-xl md:text-2xl font-roboto text-orange-200 font-extralight mb-4">{currentAnnouncement.title}</h3>
          <p className="font-exo text-sm md:text-md mb-6">{currentAnnouncement.description}</p>
          {currentAnnouncement.type === 'masterclass' || currentAnnouncement.type === 'registration' ? (
            <>
              <button 
                onClick={() => setIsModalOpen(true)}
                className="bg-transparent border-2 border-white text-white py-2 px-4 uppercase font-bold tracking-wider inline-block text-sm md:text-base mr-4"
              >
                Read More
              </button>
              <Link 
                to={currentAnnouncement.link} 
                className="bg-transparent border-2 border-white text-white py-2 px-4 uppercase font-bold tracking-wider inline-block text-sm md:text-base"
              >
                {currentAnnouncement.ctaText}
              </Link>
            </>
          ) : (
            <button 
              onClick={() => setIsModalOpen(true)}
              className="bg-transparent border-2 border-white text-white py-2 px-4 uppercase font-bold tracking-wider inline-block text-sm md:text-base"
            >
              Learn More
            </button>
          )}
        </div>
        <div className="flex justify-end mt-8">
          <button onClick={prevAnnouncement} className="mr-4 rounded-full border-white">
            <ArrowLeft size={20} />
          </button>
          <button onClick={nextAnnouncement}>
            <ArrowRight size={20} />
          </button>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2 className="font-header text-[2.5rem] leading-none tracking-wider mb-3">{currentAnnouncement.title}</h2>
        <img 
          src={currentAnnouncement.image} 
          alt={currentAnnouncement.title}
          className="w-full h-64 object-cover mb-6 rounded-lg"
        />
        <div className="font-lexend text-[1rem]" dangerouslySetInnerHTML={{ __html: currentAnnouncement.fullContent }} />
        <div className="mt-6 flex gap-4">
          {currentAnnouncement.type !== 'masterclass' && currentAnnouncement.type !== 'registration' && (
            <Link 
              to="/register"
              className="bg-orange-700 text-white py-2 px-6 rounded inline-block"
            >
              Register Now
            </Link>
          )}
          <Link 
            to={currentAnnouncement.link}
            className="bg-transparent border-2 border-orange-700 text-orange-700 py-2 px-6 rounded inline-block"
          >
            {currentAnnouncement.ctaText}
          </Link>
        </div>
      </Modal>
    </div>
  );
};

export default Announcement;