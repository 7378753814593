// MasterClassEnrollmentForm.jsx
import React, { useState } from 'react';
import BaseForm from './BaseForm';

const API_URL = 'https://updates.mtnasap.cloud/afff/api';

const MasterClassEnrollmentForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    occupation: '',
    company: '',
    experience: '',
    interests: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\+?[\d\s-]{10,}$/;
    
    if (!formData.fullName.trim()) {
      setError("Full name is required");
      return false;
    }
    if (!formData.email.trim() || !emailRegex.test(formData.email)) {
      setError("Please enter a valid email address");
      return false;
    }
    if (!formData.phone.trim() || !phoneRegex.test(formData.phone)) {
      setError("Please enter a valid phone number");
      return false;
    }
    if (!formData.experience.trim()) {
      setError("Please describe your experience");
      return false;
    }
    if (formData.experience.length < 50) {
      setError("Experience description should be more detailed (minimum 50 characters)");
      return false;
    }
    return true;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setError('');
    setSuccess('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);
    setError('');
    setSuccess('');

    try {
      const response = await fetch(`${API_URL}/masterclass-enrollment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      
      setSuccess('Thank you for enrolling in the Master Class. We will contact you with further details soon.');
      setFormData({
        fullName: '',
        email: '',
        phone: '',
        occupation: '',
        company: '',
        experience: '',
        interests: '',
      });
      
      setTimeout(() => onClose(), 3000);
    } catch (error) {
      console.error('Master Class enrollment error:', error);
      setError('Enrollment failed. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <BaseForm title="Master Class Enrollment" onClose={onClose}>
      <form onSubmit={handleSubmit} className="space-y-4">
        {Object.entries(formData).map(([key, value]) => (
          <div key={key}>
            <label htmlFor={key} className="block text-sm font-medium text-gray-700 mb-1 uppercase">
              {key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}
              <span className="text-red-500">*</span>
            </label>
            {key === 'experience' || key === 'interests' ? (
              <textarea
                name={key}
                id={key}
                value={value}
                onChange={handleChange}
                rows="3"
                className="form-input w-full focus:ring-2 focus:ring-green-500"
                required
                placeholder={`Enter your ${key.toLowerCase()}`}
                minLength={key === 'experience' ? 50 : undefined}
              />
            ) : (
              <input
                type={key === 'email' ? 'email' : key === 'phone' ? 'tel' : 'text'}
                name={key}
                id={key}
                value={value}
                onChange={handleChange}
                className="form-input w-full focus:ring-2 focus:ring-green-500"
                required
                placeholder={`Enter your ${key.toLowerCase()}`}
              />
            )}
          </div>
        ))}

        {error && (
          <div className="bg-red-50 border-l-4 border-red-500 p-4 rounded">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm text-red-700">{error}</p>
              </div>
            </div>
          </div>
        )}

        {success && (
          <div className="bg-green-50 border-l-4 border-green-500 p-4 rounded">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg className="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm text-green-700">{success}</p>
              </div>
            </div>
          </div>
        )}

        <div className="flex justify-end space-x-4">
          <button
            type="button"
            onClick={onClose}
            className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-green-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            disabled={isSubmitting}
            className={`
              px-4 py-2 bg-green-500 text-white rounded-md
              ${isSubmitting ? 'opacity-50 cursor-not-allowed' : 'hover:bg-green-600'}
              focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500
              transition-colors duration-200
            `}
          >
            {isSubmitting ? (
              <span className="flex items-center">
                <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Enrolling...
              </span>
            ) : (
              'Enroll Now'
            )}
          </button>
        </div>
      </form>
    </BaseForm>
  );
};

export default MasterClassEnrollmentForm;