import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PageHeader from "../component/PageHeader";
import Footer from "../component/Footer";
import InvestorsRoomForm from "../component/InvestorsRoomForm";
import investorsRoomBgImage from "../assets/lounge.jpg";
import { Icon } from '@iconify/react';

const WhoShouldSecure = () => {
  const organizations = [
    { 
      title: "Banks with Strong Investment Portfolios", 
      icon: "mdi:bank",
      content: "Financial institutions with experience in funding large-scale projects across various sectors, particularly those looking to expand their portfolio in the creative and entertainment industry."
    },
    { 
      title: "Private Equity Firms and Venture Capitalists", 
      icon: "mdi:chart-line",
      content: "Firms that specialize in high-risk, high-reward investments can benefit from the untapped potential in Africa's growing film industry. By having access to top filmmakers, these firms can identify high-value projects with substantial ROI potential."
    },
    { 
      title: "Development Banks and Government Financial Institutions", 
      icon: "mdi:bank-outline",
      content: "Development finance institutions with mandates to support creative economies and regional growth should secure a presence in the Investors Room. These organizations can capitalize on projects that align with their goals of cultural and economic development."
    },
    { 
      title: "Crowdfunding Platforms and Alternative Finance Providers", 
      icon: "mdi:account-group",
      content: "Organizations offering non-traditional funding options can use the Investors Room to showcase their unique financing models, attract investors looking for alternative ways to fund films, and gain visibility in a growing market."
    }
  ];

  return (
    <div className="mt-12">
      <div className="relative">
        {organizations.map((org, index) => (
          <div key={index} className="mb-12 flex items-start">
            <div className="flex items-center justify-center w-20 h-20 bg-custom-red rounded-full flex-shrink-0">
              <Icon icon={org.icon} className="text-white text-4xl" />
            </div>
            <div className="ml-6 flex-grow">
              <h3 className="text-xl font-semibold mb-2">{org.title}</h3>
              <div className="p-4 border border-gray-300 rounded-lg bg-white shadow-md">
                <p>{org.content}</p>
              </div>
            </div>
          </div>
        ))}
        <div className="absolute top-0 bottom-0 left-10 w-0.5 bg-gray-200 -ml-0.5"></div>
      </div>
    </div>
  );
};

const BenefitsSection = () => {
  const benefits = [
    "Exclusive Access to High-Quality Projects",
    "Facilitating Strategic Deals",
    "Enhanced Brand Visibility",
    "Increased Deal Flow",
    "Long-Term Partnerships",
    "ROI and Market Expansion"
  ];

  return (
    <section className="mt-12">
      <h2 className="text-2xl font-bold mb-4">Why the Investors Room is Beneficial for Finance Organizations:</h2>
      <ul className="list-disc pl-6 space-y-2">
        {benefits.map((benefit, index) => (
          <li key={index}><strong>{benefit}</strong></li>
        ))}
      </ul>
      <p className="mt-4">
        By securing the Investors Room at AFFF, finance organizations not only facilitate critical investments but also position themselves as leaders in driving Africa's creative economy forward.
      </p>
    </section>
  );
};

const InvestorsRoomPage = () => {
  const [isInvestorsRoomFormOpen, setIsInvestorsRoomFormOpen] = useState(false);

  return (
    <div className="relative min-h-screen flex flex-col bg-[#FAF0E6] font-lexend">
      <PageHeader 
        title="AFFF INVESTORS ROOM" 
        description="A premium space for accredited companies to present projects to high-level financiers."
        backgroundImage={investorsRoomBgImage}
      />
      <main className="flex-grow p-4 sm:p-6 flex flex-col items-center justify-center">
        <div className="container mx-auto px-4 py-8 max-w-6xl mx-6">
          <div className="border-l-4 sm:border-l-8 border-[#A18749] pl-2 sm:pl-4 mb-8">
            <h2 className="font-header text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-2 tracking-wider text-custom-base">
              Who Should Secure the Investors Room?
            </h2>
          </div>
          
          <WhoShouldSecure />

          <BenefitsSection />
          
          <section className="text-center mt-16 mb-16 p-4 border border-gray-300 rounded-lg bg-white shadow-md">
            <p className="mb-8 p-12 sm:text-xl leading-relaxed">
              The Investor Room is a premium space where accredited sales and production companies, along with
              other film industry professionals, present their projects to high-level financiers. Access is restricted to
              Premium and VIP Sales & Production companies, as well as Premium Elite and Premium Elite Plus
              financiers. This exclusive setting facilitates focused presentations and networking, aiming to secure
              significant investments and foster strategic partnerships.
            </p>
            <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4">
              <button 
                onClick={() => setIsInvestorsRoomFormOpen(true)}
                className="px-10 py-2 uppercase font-bold cursor-pointer bg-custom-red rounded-tl-lg rounded-br-lg text-white font-roboto text-[18px] inline-block hover:bg-red-700 transition duration-300"
              >
                SECURE YOUR INVESTOR ROOM NOW
              </button>
              <Link 
                to="/register" 
                className="px-10 py-2 uppercase font-bold cursor-pointer border border-custom-red text-custom-red rounded-tl-lg rounded-br-lg font-roboto text-[18px] inline-block hover:bg-custom-red hover:text-white transition duration-300"
                >
                INDUSTRY PROFESSIONALS SECURE A MEETING – GET YOUR BADGE
              </Link>
            </div>
          </section>
        </div>
      </main>
      <Footer />
      {isInvestorsRoomFormOpen && (
        <InvestorsRoomForm onClose={() => setIsInvestorsRoomFormOpen(false)} />
      )}
    </div>
  );
};

export default InvestorsRoomPage;