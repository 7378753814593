import React from 'react';
import PageHeader from "../component/PageHeader";
import Footer from "../component/Footer";
import forumSpeakersBgImage from "../assets/forum-speakers-bg.jpg";

const speakersData = [
  { id: 1, name: "Joy Odetie", title: "CEO, Blue Pictures Entertainment", company: "President, Film Distribution Association of Nigeria (FDAN)" },
  { id: 2, name: "Jete Amata", title: "Filmaker", company: "Black November" },
  { id: 3, name: "Hamid Ibrahim", title: "Production Designer", company: "Iwaju" },
  { id: 4, name: "Opeoluwa Filani", title: "Industry Veteran", company: "Former General Manager, Showmax - Nigeria" },
  { id: 5, name: "Moses Babatope", title: "Group CEO", company: "The Nile Media Entertainment Group" },
  { id: 6, name: "Ekene Som Mekwunye", title: "Filmmaker, One Lagos Night", company: "Creative Head, Riverside Production - Nigeria" },
  { id: 7, name: "Funmi Onuma", title: "Country Manager", company: "Silverbird Cinemas - Ghana" },
  { id: 8, name: "Kelvin Osoo", title: "Film Director & CEO", company: "Different Dimension Productions - Kenya" },
  { id: 9, name: "Mary Ephraim", title: "President, Homelands Films", company: "National Secretary, Film Distribution Association of Nigeria (FDAN)" },
  { id: 10, name: "Mr. Obinna Okerekeocha", title: "Film Producer", company: "Director of Content, Moniepoint Inc" },
  { id: 11, name: "Mr. Promise George", title: "Group Head, Creatives and Digital Industries", company: "Bank of Industry" },
  { id: 12, name: "Edward Stevenson", title: "Industry Veteran & Retail Consultant", company: "ES Retail Investment LTD" },
  { id: 13, name: "Sir. Gabe Onah", title: "Chairman", company: "The Carnival Calabar Commission" },
  { id: 14, name: "Charles Eduebu", title: "Director-General", company: "National Broadcasting Commission" },
  { id: 15, name: "Bar. Isioma Idigbe", title: "Partner", company: "Punuka Attorneys & Solicitors" },
  { id: 16, name: "Ken Egbas", title: "Senior Adviser and Consultant", company: "Federal Minister of Sport Development, Nigeria" },
  { id: 17, name: "Amb. Ikechi Uko", title: "Travel Consultant", company: "Organizer of Akwaaba African Travel Market" },
  { id: 18, name: "Clarina De Freitas", title: "National Recruitment Specialist", company: "BizCommunity" },
  { id: 19, name: "Mr Derick", title: "", company: "" },
  { id: 20, name: "Mrs Ebele", title: "", company: "" },
  { id: 21, name: "Dr. Shaibu Husseini", title: "Director General", company: "The National Film and Video Censors Board (NFVCB)" },
  { id: 22, name: "Steve Gukas", title: "Film Director", company: "Living in Bondage" },
  { id: 23, name: "Farouk Khailann", title: "Executive Board Member", company: "Global Wealth Forum" },
  { id: 24, name: "Brenda Fashugba", title: "Regional Lead Creative Economy SSA/Head of Arts Nigeria", company: "British Council" },
  { id: 25, name: "Hamisha Daryani Ahuja", title: "Film Producer", company: "Namaste Wahala" },
  { id: 26, name: "Nora Awolowo", title: "Creative Director", company: "Rixel Studios" },
  { id: 27, name: "Bar. Yahaya Maikori", title: "Media Attorney, Member Board of Directors", company: "VFD MICROFINANCE BANK" },
  { id: 28, name: "Omotayo Inakoju", title: "Head Legal", company: "EbonyLife Group" },
  { id: 29, name: "Dr. Mories Atoki", title: "CEO", company: "ABCHealth" },
  { id: 30, name: "Victoria Abiola Ajayi", title: "CEO", company: "TVC Communications" },
];

const ForumSpeakersPage = () => {
  return (
    <div className="relative">
      <PageHeader 
        title="FORUM SPEAKERS" 
        description="Meet our esteemed industry experts and thought leaders sharing their insights at AFFF."
        backgroundImage={forumSpeakersBgImage}
      />
      <main className="bg-[#FAF0E6] mb-12 font-lexend sm:mb-24 md:mb-48 p-4 sm:p-6">
        <div className="container mx-auto px-2 sm:px-4 py-4 sm:py-8 max-w-5xl">
          <section className="mb-8">
          <div className="border-l-8 border-[#A18749] pl-4 mb-4 sm:mb-6">
              <h2 className="font-header text-[4rem] leading-none sm:text-2xl md:text-6xl font-bold mb-2 tracking-wider text-custom-base">OUR SPEAKERS</h2>
            </div>

            <p className="mb-4 text-sm sm:text-base">
              Our speakers are carefully selected for their expertise, experience, and innovative approaches in the film and finance industries. They will cover a wide range of topics, including:
            </p>
            <ul className="list-disc pl-5 mb-4 text-sm sm:text-base">
              <li>Current trends in film financing</li>
              <li>Emerging markets and opportunities in African cinema</li>
              <li>Innovative distribution strategies</li>
              <li>The impact of technology on film production and consumption</li>
              <li>Cross-border collaborations and co-productions</li>
            </ul>
          </section>
          
          <div className="border-l-8 border-[#A18749] pl-4 mb-4 sm:mb-6">
              <h2 className="font-header text-[4rem] leading-none sm:text-2xl md:text-6xl font-bold mb-2 tracking-wider text-custom-base">SPEAKING AT AFFF SUMMIT 2025:</h2>
            </div>

          <div className="flex flex-col md:flex-row">
            <div className="md:w-1/2 grid grid-cols-3 gap-2 mb-6 md:mb-0">
              {speakersData.map((speaker) => (
                <div key={speaker.id} className="relative aspect-square">
                  <div className="absolute inset-0 bg-[#5EBAB0] rounded-3xl"></div>
                  <img
                    src={`/speakers/${speaker.id.toString().padStart(2, '0')}.png`}
                    alt={speaker.name}
                    className="absolute inset-0 w-full h-full object-cover rounded-3xl"
                  />
                </div>
              ))}
            </div>
            <div className="md:w-1/2 md:pl-8">
              <h2 className="font-bold text-3xl mb-4">L - R</h2>
              {speakersData.map((speaker) => (
                <div key={speaker.id} className="mb-2">
                  <p className="font-bold">{speaker.name}</p>
                  <p className="text-sm text-gray-600">{speaker.title}, {speaker.company} </p>
                </div>
              ))}
            </div>
          </div>       
        </div>
        <div className="mt-12 pb-16 ml-32">
            <h2 className="text-3xl font-roboto font-bold mb-1">OUR Masterclass</h2>
            <p className="mb-6">Here's a rear opportunitiy to meet our industry experts and thought leaders</p>
            <div className="flex items-center">
              <span className="text-2xl font-roboto font-bold mr-4">GAIN ACCESS WITH $140 ONLY</span>
              <a 
                href="/register" 
                className="bg-[#5EBAB0] text-white px-6 py-2 rounded-full hover:bg-[#4a9b91] transition-colors"
              >
                register
              </a>
            </div>
          </div>
      </main>
      <Footer />
    </div>
  );
};

export default ForumSpeakersPage;